// Chakra imports
import { Flex, Grid, useColorModeValue } from "@chakra-ui/react";
import avatar4 from "assets/img/avatars/avatar4.png";
import anonymousLogo from "assets/img/anonymous-logo.png"
import ProfileBgImage from "assets/img/bgprofile.jpg";
import React, { useEffect, useState } from "react";
import { FaCube, FaPenFancy } from "react-icons/fa";
import { IoDocumentsSharp } from "react-icons/io5";
import Conversations from "./components/Conversations";
import Header from "./components/Header";
import PlatformSettings from "./components/PlatformSettings";
import ProfileInformation from "./components/ProfileInformation";
import Projects from "./components/Projects";
import { webservice } from "service";
import Ports from "./components/Port";
import { monitorTableData } from "variables/general";
import Devices from "./components/Devices";
import {
  CpuIcon,
  RamIcon,
  SystemIcon,
  QuotaIcon
} from "components/Icons/Icons";
import axios from "axios";
import { googleLogout, useGoogleLogin } from '@react-oauth/google';

function Profile() {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  const [profileData, setProfileData] = useState(false);
  const [user, setUser] = useState(webservice.userData);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log(codeResponse);
      setUser(codeResponse);
    },
    onError: (error) => console.log('Login Failed:', error)
  });


  const formatDeviceData = (data) => {
    const devices = [];
    for (let device of data) {
      devices.push({
        id: device.hwid,
        info: [
          {
            name: device.cpu,
            icon: <CpuIcon w='16px' h='16px' />,
          },
          {
            name: device.memory,
            icon: <RamIcon w='16px' h='16px' />,
          },
          {
            name: device.os,
            icon: <SystemIcon w='16px' h='16px' />,
          },
        ]
      })
    }
    return devices;
  };

  useEffect(
    () => {
      if (user.access_token) {
        axios.post(`/api/user`, {
          access_token: user.access_token
        }).then((res) => {
          setUser(res.data);
          webservice.setUser(res.data);
        }).catch((err) => console.log(err));
        
        axios.get(`/api/profile`, {
          headers: {
            'x-user-id': webservice.userData.id
          }
        }).then((res) => {
          setProfileData(res.data);
          console.log(res.data)
        }).catch((err) => console.log(err));
      }
    },
    [user]
  );

  useEffect(
    () => {
      if (webservice.userData) {
        axios.get(`/api/profile`, {
          headers: {
            'x-user-id': webservice.userData.id
          }
        }).then((res) => {
          setProfileData(res.data);
          console.log(res.data)
        }).catch((err) => console.log(err));
      }
    },
    []
  );

  return (
    <Flex direction='column'>
      <Header
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        avatarImage={anonymousLogo}
        name={webservice.userData ? webservice.userData.given_name : "Anonymous"}
        email={webservice.userData ? webservice.userData.email : "Anonymous@anonymous-aw.com"}
        tabs={[
          {
            name: `${profileData ? "Quota : "+profileData.data.user.limit+" ID" : "Please Login"}`,
            icon: <QuotaIcon w='16px' h='16px' />,
          },
          // {
          //   name: "5/9/2567 06:59:11",
          //   icon: <IoDocumentsSharp w='100%' h='100%' />,
          // }
        ]}
      />
      <Grid gap='22px'>
        <Devices
          title="Devices"
          device={profileData ? formatDeviceData(profileData.data.devices) : []}
        />
      </Grid>
      <Grid gap='22px'>
        {/* <Ports
          title={"Port List"}
          captions={["name", "Total TLM", "Status", "cycle", "Version", ""]}
          data={monitorTableData}
        /> */}
        {/* <PlatformSettings
          title={"Account Settings"}
          subtitle1={"ACCOUNT"}
          subtitle2={"APPLICATION"}
        />
        <ProfileInformation
          title={"Profile Information"}
          description={
            "Hi, I’m Esthera Jackson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
          }
          name={"Esthera Jackson"}
          mobile={"(44) 123 1234 123"}
          email={"esthera@simmmple.com"}
          location={"United States"}
        /> */}
        {/* <Conversations title={"Conversations"} /> */}
      </Grid>
      {/* <Projects title={"Projects"} description={"Architects design houses"} /> */}
    </Flex>
  );
}

export default Profile;
